<template>
        <base-page>
        <titlu-pagina Titlu="Facturi" @on_add_clicked="show_dialog()" :AdaugaVisible="$has_right('adaugare-factura')" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :md='4'>
                            <el-form-item label='Numar' >
                                <el-input v-model='Filters.Numar' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Serie' >
                                <el-input v-model='Filters.IdSerie' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Data emitere' >
                                <el-date-picker v-model='Filters.DataEmitere' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Nume client' >
                                <el-input v-model='Filters.NumeClient' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='CIF client' >
                                <el-input v-model='Filters.CifClient' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Reg. Com. client' >
                                <el-input v-model='Filters.RegComClient' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Furnizor' >
                                <el-input v-model='Filters.Furnizor' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='CIF furnizor' >
                                <el-input v-model='Filters.CifFurnizor' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Reg. Com. furnizor' >
                                <el-input v-model='Filters.RegComFurnizor' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Masina' >
                                <el-select class='full-width' v-model='Filters.IdMasina' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.masini' :key="'masini' + item.Id" :label='item.Numar' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Status plata' >
                                <el-select class='full-width' v-model='Filters.StatusPlata' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='platita' value='platita'></el-option>
                                    <el-option label='neplatita' value='neplatita'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Status' >
                                <el-select class='full-width' v-model='Filters.Status' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='draft' value='draft'></el-option>
                                    <el-option label='emisa' value='emisa'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Anulata' >
                                <el-checkbox class='full-width' v-model='Filters.EsteAnulata' true-label='1' false-label='0'> DA </el-checkbox>
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                            <el-button @click='reset'> Reset </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='Serie' label='Serie'></el-table-column>
            <el-table-column prop='Numar' label='Numar'></el-table-column>            
            <el-table-column label='Data emitere'>
                <template slot-scope='scope'>
                    {{ scope.row.DataEmitere | momentformat('dd.MM.YYYY HH:mm') }} 
                </template>
            </el-table-column>
            <el-table-column prop='ValoareCuTva' label='Valoare cu tva'></el-table-column>
            <el-table-column prop='ValoareFaraTva' label='Valoare fara tva'></el-table-column>
            <el-table-column prop='NumeClient' label='Nume client'></el-table-column>
            <el-table-column prop='CifClient' label='CIF client'></el-table-column>
            <el-table-column prop='RegComClient' label='Reg. Com. client'></el-table-column>
            <el-table-column prop='NumeFurnizor' label='Furnizor'></el-table-column>
            <el-table-column prop='CifFurnizor' label='CIF furnizor'></el-table-column>
            <el-table-column prop='RegComFurnizor' label='Reg. Com. furnizor'></el-table-column>
            <el-table-column prop='Masina' label='Masina'></el-table-column>
            <el-table-column prop='StatusPlata' label='Status plata'></el-table-column>
            <el-table-column prop='Status' label='Status'></el-table-column>
            <el-table-column prop='EsteAnulata' label='Anulata'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
            <template slot-scope="scope" >

                <el-tooltip v-if="$has_right('modificare-factura')" content="Modificare">
                    <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                </el-tooltip>

                <el-tooltip v-if="$has_right('stergere-factura')" content="Sterge" >
                    <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                </el-tooltip>

        </template>
        </el-table-column>
        </el-table>
    <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    <Facturi-dialog ref='dlg' @save="refresh_info()" />
</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Facturi_dialog from '@/pages/facturi/Facturi_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "facturi",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Facturi-dialog': Facturi_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                masini: [],             },
            Filters: {
                Numar: '' , IdSerie: '' , DataEmitere: '' , NumeClient: '' , CifClient: '' , RegComClient: '' , Furnizor: '' , CifFurnizor: '' , RegComFurnizor: '' , IdMasina: '-1' , StatusPlata: '-1' , Status: '-1' , EsteAnulata: '' ,             },
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            if (!this.$has_right('vizualizare-facturi')){
                  this.$router.push('/no-rights')
                  return
            }
            var response        = await this.post("facturi/get_info" );
            this.Info.masini = response.masini;
            //if( this.Filters.IdMasina.length > 0 ) this.Filters.IdMasina = this.Info.masini[0].Id;
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("facturi/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('facturi');
        },
        reset(){
            this.Filters = {
                Numar: '' , Serie: '' , DataEmitere: '' , NumeClient: '' , CifClient: '' , RegComClient: '' , Furnizor: '' , CifFurnizor: '' , RegComFurnizor: '' , IdMasina: '-1' , StatusPlata: '-1' , Status: '-1' , EsteAnulata: '' ,             };
            this.refresh_info();
        },

        async delete_item( item ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("facturi/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
